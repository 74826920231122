<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="text-center d-flex justify-content-center pt-5" v-if="isLoading">
        <b-spinner class="text-danger-light" label="Loading..."></b-spinner>
      </b-row>
      <b-row class="text-center d-flex justify-content-center" v-else>
      <div class="text-center mt-5 mb-5" v-if="!school">
        <h2 class="text-primary-dark">{{ $t('School Information') }}</h2>
        <h5 class="text-gray-light">{{ $t('You have not applied for school information, if interested, you can apply below.') }}</h5>
        <b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button>
      </div>
      <div class="text-center mt-5 mb-5" v-else>
        <div v-if="school && school.status == 1">
          <h2 class="text-primary-dark">{{ $t('Waiting for approve') }}</h2>
          <h5 class="text-gray-light">{{ $t('Waiting for approve from WYA') }}</h5>
          <b-button pill :to="{name: 'schoolRegisterInformation'}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('View detail') }}</b-button>
        </div>
      </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'SchoolRegister',
  title: ' | School register',
  data () {
    return {
      isLoading: true,
      school: null
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  async mounted () {
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    await this.$store.dispatch('userProfile')
    await this.getSchool()
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getSchool () {
      this.$store.dispatch('getMySchool', { user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          if (response.data.results.length > 0) {
            this.school = response.data.results[0]
            if (this.school.status !== 1) {
              this.$router.push({ name: 'schoolProfile' })
            }
          }
        }
        this.isLoading = false
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
